// App.js
import './App.css';
import Home from "./Home";
import Topbar from "./component/topbar";
import Menubar from './component/menubar';
import Footer from './component/footer';
import Data_table from './component/data_table';
import Page_color from './component/page_color';
import Login from './component/login';
import User_profile from './component/user_profile';
import User_add from './component/user_add';
import Role_permission from './component/role_permission';
import Customer_list from './component/customer_list';
import Customer_add from './component/customer_add';
import Customer_profile from './component/customer_profile';
import Body_part_list from './component/body_part_list';
import LoaderWrapper from './LoaderWrapper'; // Import the wrapper component
import Problem_list from './component/problem_list';
import Post_details from './component/post_details';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Topbar />
        <Routes>
          <Route element={<LoaderWrapper />}>
            <Route path="/" element={<Home />} />
            <Route path="/data_table" element={<Data_table />} />
            <Route path="/role_permission" element={<Role_permission />} />
            <Route path="/user/:id" element={<User_profile />} />
            <Route path="/user_add" element={<User_add />} />
            <Route path="/login" element={<Login />} />
            <Route path="/customer_list" element={<Customer_list />} />
            <Route path="/customer_add" element={<Customer_add />} />
            <Route path="/customer/:id" element={<Customer_profile />} />
            <Route path="/body-part/:service_type" element={<Body_part_list />} />
            <Route path="/problems/:service_type/:bodypart_id" element={<Problem_list />} />
            <Route path="/details/:service_type/:bodypart_id/:problem_id" element={<Post_details />} />
          </Route>
        </Routes>
        <Page_color />
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
