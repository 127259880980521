import { useEffect, useState } from 'react';
import BASE_URL from './config';

const useFetchDataFromApi = (endpoint) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            // const token = localStorage.getItem('token'); // Retrieve token from local storage
            setLoading(true); // Set loading to true
            
            try {
                const url = `${BASE_URL}${endpoint}`
                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        // 'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
                    },
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok ' + response.statusText);
                }

                const result = await response.json();
                console.log("from main js",result)
                setData(result); // Update the state with fetched data
            } catch (error) {
                setError(error); // Set error state
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false); // Set loading to false
            }
        };

        fetchData();
    }, [endpoint]); // Dependency on url to refetch when it changes

    return { data, loading, error }; // Return data, loading state, and error
};

export default useFetchDataFromApi;
