import React from 'react'
import { Link } from 'react-router-dom'


function Topbar() {
  return (
    <div className="Topbar">
    <nav class="navbar bg-primary sticky">
      <div class="sidebar-brand">
      <Link class="navbar-brand" to='/' > <img alt="image" style={{ height: '60px' }}   src="/assets/img/logo.png" class="header-logo" /> <span
                class="logo-name">A2ZBody</span>  
            </Link>
          </div>
        
    </nav>
    </div>

  )
}

export default Topbar