// Loader.js
import React from 'react';
import './Loader.css';


const Loader = () => {
    return (
        <div className="loader-container">
            <img src='/assets/img/animated_logo.gif' alt="Loading..." className="loader-gif" />
        </div>
    );
};

export default Loader;
