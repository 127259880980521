import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function Login() {
  const [employee_id, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate(); // for navigation after successful login

  // API URL (replace with your actual login API URL)
  const apiUrl = 'http://10.20.2.222:8001/api/login/';

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault(); // prevent the form from reloading the page

    // Reset error message
    setErrorMessage('');

    // Prepare data for the API request
    const loginData = {
      employee_id: employee_id,
      password: password,
    };

    try {
      // Send POST request to the login API
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(loginData),
      });
      console.log("API Response1:", response);
      const result = await response.json();
      console.log("API Response:", result);
      
      if (response.ok) {
        // If login is successful, store the token
        localStorage.setItem('token', result.token);

        // Redirect to dashboard or any other page
        navigate('/dashboard');
      } else {
        // If login fails, display an error message
        setErrorMessage(result.message || 'Login failed. Please try again.');
      }
    } catch (error) {
      // Handle network errors or other unexpected errors
      setErrorMessage('An error occurred. Please try again later.');
    }
  };

  return (
    <section className="section">
      <div className="container mt-5">
        <div className="row">
          <div className="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
            <div className="card card-primary">
              <div className="card-header">
                <h4>Login</h4>
              </div>
              <div className="card-body">
                <form onSubmit={handleSubmit} className="needs-validation">
                  <div className="form-group">
                    <label htmlFor="employee_id">Employee ID</label>
                    <input
                      id="employee_id"
                      type="text"
                      onChange={(e) => setEmail(e.target.value)}
                      value={employee_id}
                      className="form-control"
                      name="employee_id"
                      required
                      autoFocus
                    />
                    <div className="invalid-feedback">
                      Please fill in your ID
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="d-block">
                      <label htmlFor="password" className="control-label">Password</label>
                      <div className="float-right">
                        <a href="auth-forgot-password.html" className="text-small">
                          Forgot Password?
                        </a>
                      </div>
                    </div>
                    <input
                      id="password"
                      onChange={(e) => setPassword(e.target.value)}
                      value={password}
                      type="password"
                      className="form-control"
                      name="password"
                      required
                    />
                    <div className="invalid-feedback">
                      Please fill in your password
                    </div>
                  </div>
                  {/* Display error message if login fails */}
                  {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}

                  <div className="form-group">
                    <button type="submit" className="btn btn-primary btn-lg btn-block">
                      Login
                    </button>
                  </div>
                </form>

                <div className="text-center mt-4 mb-3">
                  <div className="text-job text-muted">Login With Social</div>
                </div>
                <div className="row sm-gutters">
                  <div className="col-6">
                    <a className="btn btn-block btn-social btn-facebook">
                      <span className="fab fa-facebook"></span> Facebook
                    </a>
                  </div>
                  <div className="col-6">
                    <a className="btn btn-block btn-social btn-twitter">
                      <span className="fab fa-twitter"></span> Twitter
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-5 text-muted text-center">
              Don't have an account? <a href="auth-register.html">Create One</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Login;
