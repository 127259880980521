import React, { useState } from 'react';
import useFetchDataFromApi from '../fetch_data_from_api';
import usePostDatainAPI from '../post_data_in_api';
import { useNavigate } from 'react-router-dom';

function User_add() {
    // Load data from API
    const { data: department } = useFetchDataFromApi('http://10.20.2.222:8001/api/department_list/');
    const { data: role } = useFetchDataFromApi('http://10.20.2.222:8001/api/role/');

    // Form state
    const [selectedDepartment, setSelectedDepartment] = useState('');
    const [selectedRoles, setSelectedRoles] = useState([]);
    const [imageFile, setImageFile] = useState(null); // New state to handle image file
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        role: '',
        employee_id: '',
        textarea: '',
        birthDate: '',
        dateTimeLocal: '',
        password: '',
        isDepartmentHead: 'Yes',
    });

    // URL state for dynamic fetching of items based on selected department
    const [url, setUrl] = useState('http://10.20.2.222:8001/api/department_list/');
    const { data: itemFromDepartment, loading, error } = useFetchDataFromApi(url);

    // Post data in API
    const { data: post_data, postData } = usePostDatainAPI('http://10.20.2.222:8001/api/user_list/');

    const handleSelectChange = (e) => {
        const selectedValue = e.target.value;
        setSelectedDepartment(selectedValue);
        if (selectedValue) {
            setUrl(`http://10.20.2.222:8001/inventory/api/item-master/${selectedValue}/`);
        }
    };

    const handleRoleChange = (e) => {
        const selectedOptions = Array.from(e.target.selectedOptions, option => option.value);
        setSelectedRoles(selectedOptions);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleFileChange = (e) => {
        setImageFile(e.target.files[0]); // Save the file to state
    };
    const navigate = useNavigate();
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Create FormData object
        const dataToSubmit = new FormData();
        dataToSubmit.append('name', formData.name);
        dataToSubmit.append('email', formData.email);
        dataToSubmit.append('employee_id', formData.employee_id);
        dataToSubmit.append('department', selectedDepartment);
        dataToSubmit.append('role', selectedRoles);
        dataToSubmit.append('textarea', formData.textarea);
        dataToSubmit.append('birthDate', formData.birthDate);
        dataToSubmit.append('dateTimeLocal', formData.dateTimeLocal);
        dataToSubmit.append('password', formData.password);
        dataToSubmit.append('isDepartmentHead', formData.isDepartmentHead);

        // Append the image file if it exists
        if (imageFile) {
            dataToSubmit.append('photo', imageFile);
        }
        
        // Call the postData function with FormData
        const response = await postData(dataToSubmit);
        console.log('Response:', response);
        if (response) {

            // Redirect to the list page (e.g., '/user-list') after successful delete
            navigate('/data_table');
        }
    };

    return (
        <div className="main-content">
            <section className="section">
                <div className="section-body">
                    <div className="row">
                        <div className="col-12 ">
                            <div className="card">
                                <div className="card-header">
                                    <h4>User Entry</h4>
                                </div>
                                <div className="card-body">
                                    <form onSubmit={handleSubmit}>
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="form-group ">
                                                    <label>Name</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="name"
                                                        value={formData.name}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="form-group ">
                                                    <label>Employee ID</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="employee_id"
                                                        value={formData.employee_id}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="form-group ">
                                                    <label>Department</label>
                                                    <select
                                                        className="form-control"
                                                        value={selectedDepartment}
                                                        onChange={handleSelectChange}
                                                    >
                                                        <option selected disabled> Select Department </option>
                                                        {department?.map((dept) => (
                                                            <option key={dept.id} value={dept.id}>
                                                                {dept.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="form-group">
                                                    <label>Role (Multiple)</label>
                                                    <select
                                                        className="form-control"
                                                        multiple
                                                        data-height="100%"
                                                        value={selectedRoles}
                                                        onChange={handleRoleChange}
                                                    >
                                                        <option selected disabled> Select Role </option>
                                                        {Object.entries(role).map(([id, name]) => (
                                                            <option key={id} value={id}>
                                                                {name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="form-group">
                                                    <label>Textarea</label>
                                                    <textarea
                                                        className="form-control"
                                                        name="textarea"
                                                        value={formData.textarea}
                                                        onChange={handleInputChange}
                                                    ></textarea>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="form-group">
                                                    <label>Birth-Date</label>
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        name="birthDate"
                                                        value={formData.birthDate}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="form-group">
                                                    <label>Datetime Local</label>
                                                    <input
                                                        type="datetime-local"
                                                        className="form-control"
                                                        name="dateTimeLocal"
                                                        value={formData.dateTimeLocal}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="form-group">
                                                    <label>Email</label>
                                                    <input
                                                        type="email"
                                                        className="form-control"
                                                        name="email"
                                                        value={formData.email}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="form-group">
                                                    <label>Image</label>
                                                    <input type="file" className="form-control" onChange={handleFileChange} />
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="form-group">
                                                    <label>Password</label>
                                                    <input
                                                        type="password"
                                                        className="form-control"
                                                        name="password"
                                                        value={formData.password}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="form-group">
                                                    <label className="d-block">Is Department Head</label>
                                                    <div className="form-check">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="isDepartmentHead"
                                                            value="Yes"
                                                            checked={formData.isDepartmentHead === 'Yes'}
                                                            onChange={handleInputChange}
                                                        />
                                                        <label className="form-check-label">
                                                            Yes
                                                        </label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="isDepartmentHead"
                                                            value="No"
                                                            checked={formData.isDepartmentHead === 'No'}
                                                            onChange={handleInputChange}
                                                        />
                                                        <label className="form-check-label">
                                                            No
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="form-group">
                                                    <label>Time</label>
                                                    <input type="time" className="form-control" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-footer text-right">
                                            <button id="toastr-2" className="btn btn-primary mr-1" type="submit">Submit</button>
                                            <button id="toastr-3" className="btn btn-secondary" type="reset">Reset</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default User_add;
