import React, { useState } from 'react'
import useFetchDataFromApi from '../fetch_data_from_api'
import usePutDataToApi from '../put_data_in_api';

function Role_permission() {
  // Load data from API
  const { data:user_list, loading, error } = useFetchDataFromApi('http://10.20.2.222:8001/api/user_list/'); // All code for fetch api data is in fetch_data_from_api.js
  const { data: role } = useFetchDataFromApi('http://10.20.2.222:8001/api/menu/');

  const [checkedState, setCheckedState] = useState({});

  // Handle menu checkbox change
  const handleMenuChange = (menuValue) => {
    setCheckedState((prevState) => {
      const newState = { ...prevState };
      const isChecked = !prevState[`menu-${menuValue}`];
      newState[`menu-${menuValue}`] = isChecked;

      // Split to get module and menu indices
      const [moduleValue, menuIndex] = menuValue.split('.');

      // Check for the existence of the menu and get its permissions
      const permissions = role[parseInt(moduleValue) - 3]?.menu[menuIndex - 1]?.permission || {}; // Use optional chaining

      Object.keys(permissions).forEach((permKey, permIndex) => {
        const permissionValue = `${menuValue}.${permIndex + 1}`;
        newState[`perm-${permissionValue}`] = isChecked; // Set child permissions based on menu checked state
      });

      return newState;
    });
  };

  const renderPermissions = (permissions, moduleIndex, menuIndex) => {
    return Object.keys(permissions).map((permKey, permIndex) => {
      const permission = permissions[permKey];
      const value = `${moduleIndex + 3}.${menuIndex + 1}.${permIndex + 1}`;
      return (
        <div key={`perm-${permIndex}`} className="form-check">
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              id={`customCheck-${value}`}
              value={value}
              checked={!!checkedState[`perm-${value}`]}
              onChange={() => setCheckedState((prev) => ({ ...prev, [`perm-${value}`]: !prev[`perm-${value}`] }))}
            />
            <label className="custom-control-label" htmlFor={`customCheck-${value}`}>
              {permission.Name || permission.name}
            </label>
          </div>
        </div>
      );
    });
  };

  const renderMenus = (menus, moduleIndex) => {
    return Object.keys(menus).map((menuKey, menuIndex) => {
      const menu = menus[menuKey];
      const menuValue = `${moduleIndex + 3}.${menuIndex + 1}`;

      return (
        <div key={`menu-${menuIndex}`} className="form-group row align-items-center">
          <div className="col-sm-3">
            <div className="form-check">
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id={`customCheck-menu-${menuValue}`}
                  value={menuValue}
                  checked={!!checkedState[`menu-${menuValue}`]}
                  onChange={() => handleMenuChange(menuValue)} // Only this handles child checkboxes
                />
                <label className="custom-control-label" htmlFor={`customCheck-menu-${menuValue}`}>
                  {menu.Name}
                </label>
              </div>
            </div>
          </div>
          <div className="col-sm-9 d-flex justify-content-between">
            {renderPermissions(menu.permission, moduleIndex, menuIndex)}
          </div>
        </div>
      );
    });
  };

  const renderModules = (modules) => {
    return Object.keys(modules).map((moduleKey, moduleIndex) => {
      const module = modules[moduleKey];

      return (
        <div key={`module-${moduleIndex}`} className="card">
          <div className="card-header">
            <h4>{module.Name}</h4>
          </div>
          <div className="card-body">
            {renderMenus(module.menu, moduleIndex)}
          </div>
        </div>
      );
    });
  };



  const [formData, setFormData] = useState({
      name: '',
      
  });

  // Using the custom hook for PUT request
  const { putData} = usePutDataToApi(`http://10.20.2.222:8001/api/user_list/`);
  console.log("formData",formData)
  const handleSubmit = async (e) => {
      e.preventDefault();
      // PUT data to API using the custom hook
      await putData(formData);
  };

  return (
      <div className="main-content">
          <section className="section">
              <div className="section-body">
                  <div className="row">
                      <div className="col-12 ">
                          <div className="card">
                              <div className="card-header">
                                  <h4>Role Permission</h4>
                              </div>
                              <div className="card-body">
                              
                              
                                  <form >
                                  <div className="row">
                                    <div className="col-6">
                                            <div class="form-group">
                                            <label>Select User</label>
                                            <select class="form-control select2">
                                              {user_list?.map((user) => (
                                                  <option key={user.id} value={user.id}>
                                                      {user.name} - {user.employee_id}
                                                  </option>
                                              ))}
                                            </select>
                                            </div>
                                        </div> 
                                        <div className="col-6 text-right">
                                            <div className="card-footer text-right">
                                                <button id="toastr-2" className="btn btn-primary mr-1" type="submit">Submit</button>
                                                <button id="toastr-3" className="btn btn-secondary" type="reset">Reset</button>
                                            </div>
                                    
                                        </div>
                                        <div className="col-12">
                                        {renderModules(role)}
                                        {/* <div class="card">
                                            <div class="card-header">
                                                <h4>INVENTORY</h4>
                                            </div>
                                            <div class="card-body">
                                                
                                            <div class="form-group row align-items-center">
                                            
                                            <div class="col-sm-3">
                                                <div class="form-check">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="customCheck" />
                                                    <label class="custom-control-label" for="customCheck">Product</label>
                                                </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-9 d-flex justify-content-between">
                                                <div class="form-check">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="customCheck1" />
                                                    <label class="custom-control-label" for="customCheck1">Product Create</label>
                                                </div>
                                                </div>
                                                <div class="form-check">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="customCheck2" />
                                                    <label class="custom-control-label" for="customCheck2">Product View</label>
                                                </div>
                                                </div>
                                                <div class="form-check">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="customCheck3" />
                                                    <label class="custom-control-label" for="customCheck3">Product Update</label>
                                                </div>
                                                </div>
                                                <div class="form-check">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="customCheck4" />
                                                    <label class="custom-control-label" for="customCheck4">Product Delete</label>
                                                </div>
                                                </div>
                                            </div>
                                            </div>
                                            </div>
                                            
                                        </div> */}

                                        </div>
                                        </div>
                                        
                                  </form>
                              </div>
                              
                          </div>
                      </div>
                  </div>
              </div>
          </section>
      </div>
  );
}

export default Role_permission