import React from 'react'
import useFetchDataFromApi from '../fetch_data_from_api';
import useUserCountry from '../user_location';
import { Link } from 'react-router-dom';

function Page_color() {

  const apiUrl = `/api/service_type/`;
  const { data, loading, error } = useFetchDataFromApi(apiUrl);

  return (
    <div class="settingSidebar">
          <a href="javascript:void(0)" class="settingPanelToggle"> <i class="fa fa-spin fa-cog"></i>
          </a>
          <div class="settingSidebar-body ps-container ps-theme-default">
            <div class=" fade show active">
              <div class="setting-panel-header">Menu
              </div>
              <div class="p-15 border-bottom">
                <h6 class="font-medium m-b-10">Search Any Topic</h6>
                <form >
                  <input class="form-control" type="search" placeholder="Search" aria-label="Search" />
                </form>
                
              </div>
              <div class="p-15 border-bottom">
              <h6 class="font-medium m-b-10">Select Category</h6>
                {data.map((data,counter)=>(
                  <label class="selectgroup-item">
                    <Link to={`/body-part/${data.slug}/`} class="selectgroup-button text-decoration-none">{useUserCountry === 'BD' ? data?.name_bn : data?.name_en}</Link>
                  </label>
                ))}
              </div>
              <div class="p-15 border-bottom">
                <h6 class="font-medium m-b-10">Theme</h6>
                <div class="selectgroup layout-color w-50">
                  <label class="selectgroup-item">
                    <input type="radio" name="value" value="1" class="selectgroup-input-radio select-layout" checked />
                    <span class="selectgroup-button">Light</span>
                  </label>
                  <label class="selectgroup-item">
                    <input type="radio" name="value" value="2" class="selectgroup-input-radio select-layout" />
                    <span class="selectgroup-button">Dark</span>
                  </label>
                </div>
              </div>
              <div class="p-15 border-bottom">
                <h6 class="font-medium m-b-10">Color Theme</h6>
                <div class="theme-setting-options">
                  <ul class="choose-theme list-unstyled mb-0">
                    <li title="white" class="active">
                      <div class="white"></div>
                    </li>
                    <li title="cyan">
                      <div class="cyan"></div>
                    </li>
                    <li title="black">
                      <div class="black"></div>
                    </li>
                    <li title="purple">
                      <div class="purple"></div>
                    </li>
                    <li title="orange">
                      <div class="orange"></div>
                    </li>
                    <li title="green">
                      <div class="green"></div>
                    </li>
                    <li title="red">
                      <div class="red"></div>
                    </li>
                  </ul>
                </div>
              </div>
              
              <div class="p-15 border-bottom">
                <div class="theme-setting-options">
                  <label class="m-b-0">
                    <input type="checkbox" name="custom-switch-checkbox" class="custom-switch-input"
                      id="sticky_header_setting" />
                    <span class="custom-switch-indicator"></span>
                    <span class="control-label p-l-10">Sticky Header</span>
                  </label>
                </div>
              </div>
              <div class="mt-4 mb-4 p-3 align-center rt-sidebar-last-ele">
                <a href="#" class="btn btn-icon icon-left btn-primary btn-restore-theme">
                  <i class="fas fa-undo"></i> Restore Default
                </a>
              </div>
            </div>
          </div>
        </div>
  )
}

export default Page_color