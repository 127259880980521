import React, { useState } from 'react';
import useFetchDataFromApi from '../fetch_data_from_api';
import { Link } from 'react-router-dom';

function Data_table() {
  // State for pagination, search term, and search input
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchInput, setSearchInput] = useState('');

  // Construct API URL with page and search term
  const apiUrl = `http://10.20.2.222:8001/api/user-list/?page=${currentPage}${searchTerm ? `&search=${searchTerm}` : ''}`;
  const { data, loading, error } = useFetchDataFromApi(apiUrl);

  // Handle page change
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  // Update search input value
  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value);
  };

  // Trigger search on button click
  const handleSearchClick = () => {
    setSearchTerm(searchInput); // Set searchTerm to trigger API call
    setCurrentPage(1); // Reset to first page on new search
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <div className="main-content">
      <section className="section">
        <div className="section-body">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header d-flex justify-content-between">
                  <div className='col-3 text-left'>
                    <h4>User List</h4>
                  </div>
                  <div className='col-6 text-right'>
                    <div className="input-group">
                      <input type="text" className="form-control" placeholder="Search by Employee ID or Name" value={searchInput} onChange={handleSearchInputChange} />
                      <div className="input-group-append">
                        <button className="btn btn-primary" onClick={handleSearchClick}>
                          <i className="fas fa-search"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className='col-3 text-right'>
                    <Link to={`/user_add/`} className="btn btn-icon btn-primary">
                      <i className="fas fa-plus"></i>
                    </Link>
                  </div>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-striped" id="table-1">
                      <thead>
                        <tr>
                          <th className="text-center">Employee ID</th>
                          <th>Employee Name</th>
                          <th>Department</th>
                          <th>Designation</th>
                          <th>Role</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.results.length > 0 ? (
                          data.results.map((item, index) => (
                            <tr key={index}>
                              <td>{item?.employee_id}</td>
                              <td>{item?.name}</td>
                              <td>{item.department}</td>
                              <td>{item.designation}</td>
                              <td>{item.primary_role_name}</td>
                              <td>
                                <div className={`badge badge-${item?.color} badge-shadow`}>{item.status}</div>
                              </td>
                              <td>
                                <Link to={`/user/${item.id}/`} className="btn btn-primary">Detail</Link>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="7" className="text-center">No data available</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className="pagination-controls">
                    <button
                      className="btn btn-secondary mr-2"
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={!data.previous}
                    >
                      Previous
                    </button>
                    <span>Page {currentPage}</span>
                    <button
                      className="btn btn-secondary ml-2"
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={!data.next}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Data_table;
