import { useEffect, useState } from 'react';
import axios from 'axios';

const useUserCountry = () => {
  // State to store the user's country
  const [userCountry, setUserCountry] = useState('');
  const [loading, setLoading] = useState(true);  // To track loading state
  const [error, setError] = useState(null);      // To handle errors

  useEffect(() => {
    const getUserCountry = async () => {
      try {
        // Fetch country info from IPinfo API (with full URL)
        const response = await axios.get('https://ipinfo.io?token=a8ac20f3d13e62');
        const country = response.data.country;  // The country code (e.g., "US" or "BD")
        setUserCountry(country);  // Set the user's country
      } catch (err) {
        setError(err);  // Handle errors
        console.error('Error fetching country:', err);
      } finally {
        setLoading(false);  // Set loading to false after the request
      }
    };

    getUserCountry();
  }, []);

  return { userCountry, loading, error };
};

export default useUserCountry;