import React from 'react'

function Footer() {
  return (
    <footer class="main-footer" >
        <div class="footer-left">
        <a href="/" >a2zBody</a>
        </div>
        <div class="footer-right">
        <a href="" >Contact</a>
        </div>
    </footer>
  )
}

export default Footer