import React from 'react'
import Card from './component/card';
import { Link } from 'react-router-dom';
import { FaTv } from 'react-icons/fa';
import useFetchDataFromApi from './fetch_data_from_api';
import BigCard from './component/big_card';


function Home() {
    const newstyle = {
        backgroundColor: 'green',
      };
    
      const apiUrl = `/api/service_type/`;
      const { data, loading, error } = useFetchDataFromApi(apiUrl);

  return (
    <div className='Home'>
         <div id="app">
        <section class="section">
        <div class="container d-flex align-items-center justify-content-center vh-100">
    <div class="row">
        <div class="col-12 text-center mb-4">
            <div class="login-brand">
                a2zBody
            </div>
        </div>
            {data.map((data,counter)=>(
                <BigCard data={data}/>
            ))}
        
    </div>
</div>

        
        </section>
    </div>
    <div class="simple-footer">
                Copyright &copy; A2ZBody.com 2024
                </div>
        
    </div>
  )
}

export default Home