import React, { useState } from 'react'
import useFetchDataFromApi from '../fetch_data_from_api';
import { Link, useNavigate, useParams } from 'react-router-dom';
import usePutDataToApi from '../put_data_in_api';
import useDeleteDataFromApi from '../delete_data_in_api';

function User_profile() {
    const { id } = useParams();
    const { data:user_data } = useFetchDataFromApi(`http://10.20.2.222:8001/api/user_list/${id}/`); // All code for fetch api data is in fetch_data_from_api.js
    
    const [formData, setFormData] = useState({
        name: '',
        
    });

    // Using the custom hook for PUT request
    const { putData, loading, data, error } = usePutDataToApi(`http://10.20.2.222:8001/api/user_list/${id}/`);

    const handleSubmit = async (e) => {
        e.preventDefault();
        // PUT data to API using the custom hook
        await putData(formData);
    };

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const { data:deleteuser,  deleteData } = useDeleteDataFromApi('http://10.20.2.222:8001/api/user_list');
    const navigate = useNavigate(); // useNavigate hook for redirection
    const handleDelete = async (deleteId) => {
        if (deleteId) {
            const response = await deleteData(deleteId);  // Call the deleteData function with the resource ID
            if (response) {
                // Redirect to the list page (e.g., '/user-list') after successful delete
                navigate('/data_table');
            }
        }
    };

    return (
        <div class="main-content">
        <section class="section">
            <div class="section-body">
            <div class="row mt-sm-4">
                <div class="col-12 col-md-12 col-lg-4">
                <div class="card author-box">
                    <div class="card-body">
                    <div class="author-box-center">
                        <img alt="" src={`http://10.20.2.222:8001${user_data?.photo}`} class="rounded-circle author-box-picture" />
                        <div class="clearfix"></div>
                        <div class="author-box-name">
                        <Link href="">{user_data?.name}</Link>
                        </div>
                        <div class="author-box-job">{user_data?.designation}</div>
                    </div>
                    <div class="text-center">
                        
                        <div class="mb-2 mt-3">
                        <div class="text-small font-weight-bold">Follow {user_data?.name} On</div>
                        </div>
                        <Link href="" class="btn btn-social-icon mr-1 btn-facebook">
                        <i class="fab fa-facebook-f"></i>
                        </Link>
                        <Link href="" class="btn btn-social-icon mr-1 btn-twitter">
                        <i class="fab fa-twitter"></i>
                        </Link>
                        <Link href="" class="btn btn-social-icon mr-1 btn-github">
                        <i class="fab fa-github"></i>
                        </Link>
                        <Link href="" class="btn btn-social-icon mr-1 btn-instagram">
                        <i class="fab fa-instagram"></i>
                        </Link>
                        <div class="w-100 d-sm-none"></div>
                    </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-header">
                    <h4>Personal Details</h4>
                    </div>
                    <div class="card-body">
                    <div class="py-4">
                        <p class="clearfix">
                        <span class="float-left">
                            Birthday
                        </span>
                        <span class="float-right text-muted">
                            30-05-1998
                        </span>
                        </p>
                        <p class="clearfix">
                        <span class="float-left">
                            Phone
                        </span>
                        <span class="float-right text-muted">
                            (0123)123456789
                        </span>
                        </p>
                        <p class="clearfix">
                        <span class="float-left">
                            Mail
                        </span>
                        <span class="float-right text-muted">
                            test@example.com
                        </span>
                        </p>
                        <p class="clearfix">
                        <span class="float-left">
                            Facebook
                        </span>
                        <span class="float-right text-muted">
                            <Link href="">John Deo</Link>
                        </span>
                        </p>
                        <p class="clearfix">
                        <span class="float-left">
                            Twitter
                        </span>
                        <span class="float-right text-muted">
                            <Link href="">@johndeo</Link>
                        </span>
                        </p>
                    </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-header">
                    <h4>Skills</h4>
                    </div>
                    <div class="card-body">
                    <ul class="list-unstyled user-progress list-unstyled-border list-unstyled-noborder">
                        <li class="media">
                        <div class="media-body">
                            <div class="media-title">Java</div>
                        </div>
                        <div class="media-progressbar p-t-10">
                            <div class="progress" data-height="6">
                            <div class="progress-bar bg-primary" data-width="70%"></div>
                            </div>
                        </div>
                        </li>
                        <li class="media">
                        <div class="media-body">
                            <div class="media-title">Web Design</div>
                        </div>
                        <div class="media-progressbar p-t-10">
                            <div class="progress" data-height="6">
                            <div class="progress-bar bg-warning" data-width="80%"></div>
                            </div>
                        </div>
                        </li>
                        <li class="media">
                        <div class="media-body">
                            <div class="media-title">Photoshop</div>
                        </div>
                        <div class="media-progressbar p-t-10">
                            <div class="progress" data-height="6">
                            <div class="progress-bar bg-green" data-width="48%"></div>
                            </div>
                        </div>
                        </li>
                    </ul>
                    </div>
                </div>
                </div>
                <div class="col-12 col-md-12 col-lg-8">
                <div class="card">
                    <div class="padding-20">
                    <ul class="nav nav-tabs" id="myTab2" role="tablist">
                        <li class="nav-item">
                        <a class="nav-link active" id="home-tab2" data-toggle="tab" href="#about" role="tab"
                            aria-selected="true">About</a>
                        </li>
                        <li class="nav-item">
                        <a class="nav-link" id="profile-tab2" data-toggle="tab" href="#settings" role="tab"
                            aria-selected="false">Setting</a>
                        </li>
                    </ul>
                    <div class="tab-content tab-bordered" id="myTab3Content">
                        <div class="tab-pane fade show active" id="about" role="tabpanel" aria-labelledby="home-tab2">
                        <div class="row">
                            <div class="col-md-3 col-6 b-r">
                            <strong>Full Name</strong>
                            <br />
                            <p class="text-muted">{user_data.name}</p>
                            </div>
                            <div class="col-md-3 col-6 b-r">
                            <strong>Mobile</strong>
                            <br />
                            <p class="text-muted">{user_data.phone_no}</p>
                            </div>
                            <div class="col-md-3 col-6 b-r">
                            <strong>Email</strong>
                            <br />
                            <p class="text-muted">{user_data.email}</p>
                            </div>
                            <div class="col-md-3 col-6">
                            <strong>Location</strong>
                            <br />
                            <p class="text-muted">{user_data.country}</p>
                            </div>
                        </div>
                        <p class="m-t-30">Completed my graduation in Arts from the well known and
                            renowned institution
                            of India – SARDAR PATEL ARTS COLLEGE, BARODA in 2000-01, which was
                            affiliated
                            to M.S. University. I ranker in University exams from the same
                            university
                            from 1996-01.</p>
                        

                        </div>
                        <div class="tab-pane fade" id="settings" role="tabpanel" aria-labelledby="profile-tab2">
                        <form method="put" class="needs-validation" onSubmit={handleSubmit}>
                            <div class="card-header">
                            <h4>Edit Profile</h4>
                            </div>
                            <div class="card-body">
                            <div class="row">
                                <div class="form-group col-md-6 col-12">
                                <label>First Name</label>
                                <input type="text" class="form-control" name='name' value={formData.name} onChange={handleInputChange} />
                                <div class="invalid-feedback">
                                    Please fill in the first name
                                </div>
                                </div>
                                <div class="form-group col-md-6 col-12">
                                    <label>Email</label>
                                    <input type="email" class="form-control" name='email' value={formData.email} onChange={handleInputChange} />
                                    <div class="invalid-feedback">
                                        Please fill in the email
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                
                                <div class="form-group col-md-5 col-12">
                                <label>Phone</label>
                                <input type="tel" class="form-control" name='phone_no' value={formData.phone_no} onChange={handleInputChange} />
                                </div>
                            </div>
                            
                            </div>
                            <div class="card-footer text-right">
                            <button class="btn btn-primary">Save Changes</button>
                            </div>
                        </form>
                        <div class="card-footer text-right">
                        <button
                            className="btn btn-danger"
                            onClick={() => handleDelete(id)}  // Corrected this to be a function
                            disabled={loading}
                            >
                            {loading ? 'Deleting...' : 'Delete'}
                            </button>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </section>
        </div>
    )
}

export default User_profile