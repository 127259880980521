import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import useUserCountry from './../user_location';
import BASE_URL from '../config';

function BigCard({data}) {
    
    const { userCountry, loading, error } = useUserCountry();
    const displayName = userCountry === 'BD' ? data?.name_bn : data?.name_en;
    
  return (
    <div class="col-md-6">
        <Link to={`/body-part/${data.slug}/`} class="logo-name text-decoration-none">
            <div class="card">
                <div class="card-statistic-4">
                    <div class="align-items-center justify-content-between">
                        <div class="row">
                            <div class="col-6 pr-0 pt-3">
                                <div class="card-content">
                                    <h3 class="font-40">{displayName}</h3>
                                    <h2 class="mb-3 font-18">{data?.no_of_service} {displayName}</h2>
                                    <p class="mb-0"><span class="col-green">{data?.view_count}</span> User</p>
                                </div>
                            </div>
                            <div class="col-6 pl-0">
                                <div class="banner-img">
                                    <img src={`${BASE_URL}${data?.image}`} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </Link>
        </div>
  )
}

export default BigCard