import { useState } from 'react';

function usePutDataToApi(endpoint) {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const token = localStorage.getItem('token');

    const putData = async (body) => {
        setLoading(true);
        setError(null);

        try {
            const formData = new FormData();
            for (const key in body) {
                formData.append(key, body[key]);
            }

            const response = await fetch(endpoint, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`, // Don't set 'Content-Type', browser handles it
                },
                body: formData, // Send form data
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const result = await response.json();
            setData(result);
            return result;
        } catch (err) {
            setError(err.message || 'Something went wrong');
        } finally {
            setLoading(false);
        }
    };

    return { putData, loading, data, error };
}

export default usePutDataToApi;
