import React, { useState } from 'react';
import useFetchDataFromApi from '../fetch_data_from_api';
import usePostDatainAPI from '../post_data_in_api';
import { useNavigate } from 'react-router-dom';

function Customer_add() {
    // Load data from API
    const { data: department } = useFetchDataFromApi('http://10.20.2.222:8001/api/department_list/');
    const { data: district } = useFetchDataFromApi('http://10.20.2.222:8001/api/get_district/');

    // Form state
    const [selectedDepartment, setSelectedDepartment] = useState('');
    const [selectedDistrict, setSelectedDistrict] = useState([]);
    const [imageFile, setImageFile] = useState(null); // New state to handle image file
    const [formData, setFormData] = useState({
        name: '',
        phone_no: '',
        password_text: '',
        email: '',
        gender: '',
        upazila: '',
        address: '',
        date_of_birth: '',
        post_code: '',
        age: '',
        ref_customer: '',
    });
    
    // URL state for dynamic fetching of items based on selected department
    const [url, setUrl] = useState('http://10.20.2.222:8001/api/department_list/');
    const { data: itemFromDepartment, loading, error } = useFetchDataFromApi(url);

    // Post data in API
    const { data: post_data, postData } = usePostDatainAPI('http://10.20.2.222:8001/ccd/api/customer/');

    const handleSelectChange = (e) => {
        const selectedValue = e.target.value;
        setSelectedDepartment(selectedValue);
        if (selectedValue) {
            setUrl(`http://10.2.0.253:8001/inventory/api/item-master/${selectedValue}/`);
        }
    };

    const handleDistrictChange = (e) => {
        const selectedOptions = Array.from(e.target.selectedOptions, option => option.value);
        setSelectedDistrict(selectedOptions);
    };

    const handleGenderChange = (e) => {
        setFormData({ ...formData, gender: e.target.value });
    };
    
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleFileChange = (e) => {
        setImageFile(e.target.files[0]); // Save the file to state
    };
    const navigate = useNavigate();
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Create FormData for multipart/form-data POST
        const dataToSubmit = new FormData();
        Object.keys(formData).forEach(key => {
            dataToSubmit.append(key, formData[key]);
        });

        // Append the image file if it exists
        if (imageFile) {
            dataToSubmit.append('photo', imageFile);
        }
        
        // Call the postData function with FormData
        const response = await postData(dataToSubmit);
        console.log('Response:', response);
        if (response) {

            // Redirect to the list page (e.g., '/user-list') after successful delete
            navigate('/customer_list');
        }
    };

    return (
        <div className="main-content">
            <section className="section">
                <div className="section-body">
                    <div className="row">
                        <div className="col-12 ">
                            <div className="card">
                                <div className="card-header">
                                    <h4>Customer Entry</h4>
                                </div>
                                <div className="card-body">
                                    <form onSubmit={handleSubmit}>
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="form-group ">
                                                    <label>Name</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="name"
                                                        value={formData.name}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="form-group ">
                                                    <label>Phone No</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="phone_no"
                                                        value={formData.phone_no}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="form-group ">
                                                    <label>Age</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="age"
                                                        value={formData.age}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="form-group ">
                                                    <label>Profession</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="profession"
                                                        value={formData.profession}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="form-group">
                                                    <label>Birth-Date</label>
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        name="date_of_birth"
                                                        value={formData.date_of_birth}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="form-group">
                                                    <label>Image</label>
                                                    <input type="file" className="form-control" onChange={handleFileChange} />
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="form-group ">
                                                    <label>Gender</label>
                                                    <select
                                                        className="form-control"
                                                        value={formData.gender}
                                                        onChange={handleGenderChange} 
                                                    >
                                                        <option selected disabled> Select Gender </option>
                                                        <option value='1'> Male</option>
                                                        <option value='2'> Female</option>
                                                        <option value='3'> Other</option>
                                                        
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="form-group ">
                                                    <label>Ref. Customer</label>
                                                    <select
                                                        className="form-control"
                                                        value={selectedDepartment}
                                                        onChange={handleSelectChange}
                                                    >
                                                        <option selected disabled> Select Department </option>
                                                        {department?.map((dept) => (
                                                            <option key={dept.id} value={dept.id}>
                                                                {dept.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                           
                                            <div className="col-6">
                                                <div className="form-group">
                                                    <label>Email</label>
                                                    <input
                                                        type="email"
                                                        className="form-control"
                                                        name="email"
                                                        value={formData.email}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="form-group ">
                                                    <label>District</label>
                                                    <select
                                                        className="form-control"
                                                        value={selectedDistrict}
                                                        onChange={handleDistrictChange}
                                                    >
                                                        <option selected disabled> Select Department </option>
                                                        {district?.map((dept) => (
                                                            <option key={dept.id} value={dept.id}>
                                                                {dept.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="form-group ">
                                                    <label>Upazila</label>
                                                    <select
                                                        className="form-control"
                                                        value={selectedDepartment}
                                                        onChange={handleSelectChange}
                                                    >
                                                        <option selected disabled> Select Department </option>
                                                        {department?.map((dept) => (
                                                            <option key={dept.id} value={dept.id}>
                                                                {dept.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="form-group ">
                                                    <label>Zip Code</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="post_code"
                                                        value={formData.post_code}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="form-group ">
                                                    <label>Address</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="address"
                                                        value={formData.address}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </div>
                                            
                                        </div>
                                        <div className="card-footer text-right">
                                            <button id="toastr-2" className="btn btn-primary mr-1" type="submit">Submit</button>
                                            <button id="toastr-3" className="btn btn-secondary" type="reset">Reset</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Customer_add;
