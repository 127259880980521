
import React from 'react'
import Card from './card';
import useFetchDataFromApi from '../fetch_data_from_api';
import { useParams } from 'react-router-dom';

function Body_part_list() {
    const { service_type } = useParams();
    const apiUrl = `/api/get_service_type_wise_body_part/${service_type}/`;
    const { data, loading, error } = useFetchDataFromApi(apiUrl);
  return (
    <div id="app">
            <div class="main-wrapper main-wrapper-1 vh-100">
                <div class="navbar-bg"></div>
                <div class="main-content">
                    <section class="section">
                        <div class="row ">
                            {data.map((data,counter)=>(
                                
                                    <Card data={data}/>
                            ))}
            

                        </div>
                    </section>
                </div>
            </div>
        </div>
  )
}

export default Body_part_list