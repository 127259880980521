import React from 'react'
import { Link, useParams } from 'react-router-dom';
import useFetchDataFromApi from '../fetch_data_from_api';
import useUserCountry from '../user_location';
import BASE_URL from '../config';

function Post_details() {
  
  const { service_type, bodypart_id, problem_id } = useParams();
  const apiUrl = `/api/get_problem_details/${service_type}/${bodypart_id}/${problem_id}/`;
  const { data, loading, error } = useFetchDataFromApi(apiUrl);
  
  const apiUrl2 = `/api/get_body_part_wise_problem/${service_type}/${bodypart_id}/`;
  const { data:more_problem } = useFetchDataFromApi(apiUrl2);
  
  const apiUrl3 = `/api/get_service_type_wise_body_part/${service_type}/`;
  const { data:more_body_part } = useFetchDataFromApi(apiUrl3);

  const { userCountry } = useUserCountry();
  const post_details = userCountry === 'BD' ? data?.details_name_bn : data?.details_name_en;
  const problem_title = userCountry === 'BD' ? data?.problem_name_bn : data?.problem_name_en;
  const body_parts = userCountry === 'BD' ? data?.name_bn : data?.name_en;
  return (
    
    <div class="main-content">
    <section class="section">
    <div class="section-body">
    <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 order-sm-2 order-2">
        <div class="card">
            <div class="body">
            <div id="mail-nav">
                <button type="button" class="btn btn-danger waves-effect btn-compose m-b-15">আরো দেখুন</button>
                <ul class="online-user" id="online-offline">
                {more_problem.slice(0, 5).map((data, counter) => (
                  <li><Link to={`/details/${service_type}/${bodypart_id}/${data.id}`}> <img alt="image" src={`${BASE_URL}${data?.problem_image}`}
                          class="rounded-circle" width="35" data-toggle="tooltip" title="Sachin Pandit" />
                        {userCountry === 'BD' ? data?.problem_name_bn : data?.problem_name_en}
                      </Link>
                  </li>
                ))}
                
                </ul>
                <h5 class="b-b p-10 text-strong">শরীরের অন্য অঙ্গ</h5>
                <ul class="online-user" id="online-offline">
                {more_body_part.slice(0, 5).map((data, counter) => (
                  <li><Link to={`/problems/${service_type}/${data.id}`} > <img alt="image" src={`${BASE_URL}${data?.image}`}
                          class="rounded-circle" width="35" data-toggle="tooltip" title="Sachin Pandit" />
                        {userCountry === 'BD' ? data?.name_bn : data?.name_en}
                      </Link>
                  </li>
                  ))}
                
                </ul>
            </div>
            </div>
        </div>
    </div>
        <div class="col-xs-12 col-sm-12 col-md-9 col-lg-9 order-sm-1 order-1">
            <div class="card">
                <div class="boxs mail_listing">
                <div class="inbox-body no-pad">
                    <section class="mail-list">
                    <div class="mail-sender">
                        <div class="mail-heading">
                        <h4 class="vew-mail-header">
                            <b>{problem_title}</b>
                        </h4>
                        </div>
                        
                        <div class="media">
                        <a href="#" class="table-img m-r-15">
                            <img alt="image" src={`${BASE_URL}${data?.problem_image}`} class="rounded-circle" width="35"
                            data-toggle="tooltip" title="Sachin Pandit" />
                        </a>
                        <div class="media-body">
                            <span class="date pull-right">Updated in: {new Date(data.updated_at).toLocaleDateString('en-GB')}</span>
                            
                        </div>
                        </div>
                    </div>
                    <div class=" p-t-20" >
                    <style>
                      {`
                        .problem-details-content {
                          text-align: left !important;
                          line-height: 1.6; /* Improves readability with spacing between lines */
                          font-family: 'Arial', sans-serif; /* Clean, easy-to-read font */
                          font-size: 16px; /* Readable font size */
                          color: #333; /* Soft dark gray color for text, less harsh than black */
                          margin: 20px 0; /* Adds spacing between paragraphs */
                        }

                        .problem-details-content h1, .problem-details-content h2, .problem-details-content h3 {
                          font-family: 'Georgia', serif; /* A nice serif font for headings */
                          font-weight: bold;
                          color: #2C3E50; /* Slightly darker color for headings */
                          margin-top: 30px;
                          margin-bottom: 10px;
                        }

                        .problem-details-content p {
                          margin-bottom: 15px; /* Adds spacing between paragraphs */
                        }

                        .problem-details-content a {
                          color: #3498db; /* Adds a soft blue color for links */
                          text-decoration: none; /* Removes underlines */
                        }

                        .problem-details-content a:hover {
                          text-decoration: underline; /* Adds underline on hover for clarity */
                        }

                        .problem-details-content ul, .problem-details-content ol {
                          margin-left: 20px; /* Indents lists for better structure */
                        }

                        .problem-details-content blockquote {
                          font-style: italic; /* Adds a distinct style for quotes */
                          border-left: 4px solid #BDC3C7; /* Adds a visual cue for blockquotes */
                          padding-left: 15px;
                          margin: 20px 0;
                          color: #7f8c8d; /* Lighter color for blockquote text */
                        }
                      `}
                    </style>
                    
                      <div className="problem-details-content" style={{ textAlign: 'unset' }} dangerouslySetInnerHTML={{ __html: post_details }} /></div>
                    
                    <div class="replyBox m-t-20">
                        
                            <div class="card-body pb-0">
                                <div class="form-group">
                                <label> আপনার নাম </label>
                                <input type="text" name="title" class="form-control" required />
                                <div class="invalid-feedback">
                                    Please fill in the title
                                </div>
                                </div>
                                <div class="form-group">
                                <label>আপনার মতামত</label>
                                <textarea class="form-control"></textarea>
                                </div>
                            </div>
                            <div class="card-footer pt-0">
                                <button class="btn btn-primary">কমেন্ট</button>
                            </div>
                        
                    </div>
                    
                    </section>
                    
                </div>
                
                </div>
                
            </div>
            </div>
            <div class="col-12 col-sm-12 col-lg-12 order-sm-3 order-3">
                <div class="card">
                  <div class="card-header">
                    <h4>Comments</h4>
                  </div>
                  <div class="card-body">
                    <ul class="list-unstyled list-unstyled-border list-unstyled-noborder">
                      <li class="media">
                        <div class="media-title mb-1">Cara Stevens</div><br></br>
                        
                        <div class="media-body">
                          
                          
                          <div class="media-description text-muted">Duis aute irure dolor in reprehenderit in voluptate
                            velit esse
                            cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                            proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</div>
                          <div class="media-links">
                            <a href="#">View</a>
                            <div class="bullet"></div>
                            <a href="#">Edit</a>
                            <div class="bullet"></div>
                            <a href="#" class="text-danger">Trash</a>
                          </div>
                        </div>
                      </li>
                      <li class="media">
                        <img alt="image" class="mr-3 rounded-circle" width="70" src="assets/img/users/user-2.png" />
                        <div class="media-body">
                          <div class="media-right">
                            <div class="text-warning">Pending</div>
                          </div>
                          <div class="media-title mb-1">Airi Satou</div>
                          <div class="text-time">Yesterday</div>
                          <div class="media-description text-muted">Duis aute irure dolor in reprehenderit in voluptate
                            velit esse
                            cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                            proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</div>
                          <div class="media-links">
                            <a href="#">View</a>
                            <div class="bullet"></div>
                            <a href="#">Edit</a>
                            <div class="bullet"></div>
                            <a href="#" class="text-danger">Trash</a>
                          </div>
                        </div>
                      </li>
                      <li class="media">
                        <img alt="image" class="mr-3 rounded-circle" width="70" src="assets/img/users/user-3.png" />
                        <div class="media-body">
                          <div class="media-right">
                            <div class="text-primary">Approved</div>
                          </div>
                          <div class="media-title mb-1">Hasan Basri</div>
                          <div class="text-time">Yesterday</div>
                          <div class="media-description text-muted">Duis aute irure dolor in reprehenderit in voluptate
                            velit esse
                            cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                            proident</div>
                          <div class="media-links">
                            <a href="#">View</a>
                            <div class="bullet"></div>
                            <a href="#">Edit</a>
                            <div class="bullet"></div>
                            <a href="#" class="text-danger">Trash</a>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
        
        
    </div>
    </div>
    </section>
    </div>
  )
}

export default Post_details