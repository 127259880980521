// LoaderWrapper.js
import { useEffect, useState } from 'react';
import { useLocation, Outlet } from 'react-router-dom';
import Loader from './Loader';

const LoaderWrapper = () => {
    const [isLoading, setIsLoading] = useState(false);
    const location = useLocation();

    useEffect(() => {
        setIsLoading(true);
        const timer = setTimeout(() => setIsLoading(false), 1000);

        return () => clearTimeout(timer);
    }, [location]);

    return (
        <>
            {isLoading && <Loader />}
            <Outlet /> {/* Renders nested routes */}
        </>
    );
};

export default LoaderWrapper;
