import React from 'react'
import { Link, useParams } from 'react-router-dom'
import useUserCountry from '../user_location';
import BASE_URL from '../config';

function Cardsmall({data}) {
    const { service_type, bodypart_id } = useParams();
    const { userCountry, loading, error } = useUserCountry();
    const displayName = userCountry === 'BD' ? data?.problem_name_bn : data?.problem_name_en;
  return (
    
    <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xs-12">
        <Link to={`/details/${service_type}/${bodypart_id}/${data.slug}`} class="logo-name text-decoration-none">
        <div class="card" >
            <div class="card-statistic-4">
            <div class="align-items-center justify-content-between">
                <div class="row ">
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 pr-0 pt-3">
                    <div class="card-content">
                    <h5 class="font-20">{displayName}</h5>
                    
                    <p class="mb-0"><span class="col-green">{data?.parent_id}</span> People</p>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 pl-0">
                    <div class="banner-img">
                    <img src={`${BASE_URL}${data?.problem_image}`}  alt="" />
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        </Link>
        </div>
  )
}

export default Cardsmall