import { useState } from 'react';
import iziToast from 'izitoast';
import 'izitoast/dist/css/iziToast.min.css';
const usePostDatainAPI = (url) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const postData = async (formData) => {
        const token = localStorage.getItem('token'); // Assuming you're using token-based authentication

        if (!token) {
            setError('Token not available');
            return;
        }

        if (!url) {
            setError('URL is required');
            return;
        }

        setLoading(true); // Set loading to true while request is in progress
        setError(null); // Reset error state

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,  // Include the token if needed
                    // Do NOT set 'Content-Type' manually here, let the browser handle it for FormData
                },
                body: formData,  // Send the formData as body
            });

            if (response.ok) {
                iziToast.success({
                  title: 'Success',
                  message: 'User Added successfully!',
                  position: 'topRight',
                });
        
                // You can redirect or navigate to another page here
                // Example using React Router: navigate('/list-page')
            } else {
            throw new Error('Failed to delete item');
            }

            const result = await response.json();
            setData(result); // Update the state with the response data
            return result;  // Optionally return result for further use
        } catch (error) {
            setError(error.message || 'Something went wrong');  // Set the error state
        } finally {
            setLoading(false);  // Set loading to false after request is complete
        }
    };

    return { data, loading, error, postData };  // Return data, loading, error, and the postData function
};

export default usePostDatainAPI;
