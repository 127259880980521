import { useState } from 'react';
import iziToast from 'izitoast';
import 'izitoast/dist/css/iziToast.min.css';
const useDeleteDataFromApi = (url) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);

    const deleteData = async (id) => {
        const token = localStorage.getItem('token'); // Assuming you're using token-based authentication

        setLoading(true);
        setError(null); // Reset error state

        try {
            const response = await fetch(`${url}/${id}/`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`, // Include the token if needed
                    // 'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                iziToast.success({
                  title: 'Success',
                  message: 'User deleted successfully!',
                  position: 'topRight',
                });
        
                // You can redirect or navigate to another page here
                // Example using React Router: navigate('/list-page')
            } else {
            throw new Error('Failed to delete item');
            }

            setData({ message: 'Resource deleted successfully' });
            return { message: 'Resource deleted successfully' };  // Return success message
        } catch (error) {
            setError(error);
            console.error('Error deleting data:', error);
        } finally {
            setLoading(false);
        }
    };

    return { data, loading, error, deleteData };
};

export default useDeleteDataFromApi;
