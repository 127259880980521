import React, { useState } from 'react'
import useFetchDataFromApi from '../fetch_data_from_api';
import { Link, useNavigate, useParams } from 'react-router-dom';
import usePutDataToApi from '../put_data_in_api';
import useDeleteDataFromApi from '../delete_data_in_api';

function Customer_profile() {
    const { id } = useParams();
    const { data:user_data } = useFetchDataFromApi(`http://10.20.2.222:8001/ccd/api/customer-with-id/${id}/`); // All code for fetch api data is in fetch_data_from_api.js
    
    const [formData, setFormData] = useState({
        name: '',
        
    });

    // Using the custom hook for PUT request
    const { putData, loading, data, error } = usePutDataToApi(`http://10.20.2.222:8001/api/user_list/${id}/`);

    const handleSubmit = async (e) => {
        e.preventDefault();
        // PUT data to API using the custom hook
        await putData(formData);
    };

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const { data:deleteuser,  deleteData } = useDeleteDataFromApi('http://10.20.2.222:8001/api/user_list');
    const navigate = useNavigate(); // useNavigate hook for redirection
    const handleDelete = async (deleteId) => {
        if (deleteId) {
            const response = await deleteData(deleteId);  // Call the deleteData function with the resource ID
            if (response) {
                // Redirect to the list page (e.g., '/user-list') after successful delete
                navigate('/data_table');
            }
        }
    };

    return (
        <div class="main-content">
        <section class="section">
            <div class="section-body">
            <div class="row mt-sm-4">
                <div class="col-12 col-md-12 col-lg-4">
                <div class="card author-box">
                    <div class="card-body">
                    <div class="author-box-center">
                        <img alt="" src={`http://10.20.2.222:8001${user_data?.photo}`} class="rounded-circle author-box-picture" />
                        <div class="clearfix"></div>
                        <div class="author-box-name">
                        <Link href="">{user_data?.name}</Link>
                        </div>
                        <div class="author-box-job">{user_data?.designation}</div>
                    </div>
                    
                    </div>
                </div>
                
                
                </div>
                <div class="col-12 col-md-12 col-lg-8">
                <div class="card">
                    <div class="padding-20">
                    <ul class="nav nav-tabs" id="myTab2" role="tablist">
                        <li class="nav-item">
                        <a class="nav-link active" id="home-tab2" data-toggle="tab" href="#about" role="tab"
                            aria-selected="true">About</a>
                        </li>
                        <li class="nav-item">
                        <a class="nav-link" id="profile-tab2" data-toggle="tab" href="#settings" role="tab"
                            aria-selected="false">Setting</a>
                        </li>
                    </ul>
                    <div class="tab-content tab-bordered" id="myTab3Content">
                        <div class="tab-pane fade show active" id="about" role="tabpanel" aria-labelledby="home-tab2">
                        <div class="row">
                            <div class="col-md-4 col-6 b-r">
                                <strong>Full Name</strong>
                                <br />
                                <p class="text-muted">{user_data.name}</p>
                            </div>
                            <div class="col-md-4 col-6 b-r">
                                <strong>Mobile</strong>
                                <br />
                                <p class="text-muted">{user_data.phone_no}</p>
                            </div>
                            <div class="col-md-4 col-6 b-r">
                                <strong>Email</strong>
                                <br />
                                <p class="text-muted">{user_data.email}</p>
                            </div>
                            <div class="col-md-4 col-6 b-r">
                                <strong>Age</strong>
                                <br />
                                <p class="text-muted">{user_data.age}</p>
                            </div>
                            <div class="col-md-4 col-6 b-r">
                                <strong>Profession</strong>
                                <br />
                                <p class="text-muted">{user_data.profession}</p>
                            </div>
                            <div class="col-md-4 col-6 b-r">
                                <strong>Date Of Birth</strong>
                                <br />
                                <p class="text-muted">{user_data.date_of_birth}</p>
                            </div>
                            <div class="col-md-4 col-6 b-r">
                                <strong>Gender</strong>
                                <br />
                                <p class="text-muted">{user_data.gender_text}</p>
                            </div>
                            <div class="col-md-4 col-6 b-r">
                                <strong>Ref customer</strong>
                                <br />
                                <p class="text-muted">{user_data.ref_customer_name}</p>
                            </div>
                            
                            <div class="col-md-4 col-6">
                                <strong>District</strong>
                                <br />
                                <p class="text-muted">{user_data.district_name}</p>
                            </div>
                            <div class="col-md-4 col-6">
                                <strong>Upazila</strong>
                                <br />
                                <p class="text-muted">{user_data.upazila_name}</p>
                            </div>
                            <div class="col-md-4 col-6">
                                <strong>Zip/Post Code</strong>
                                <br />
                                <p class="text-muted">{user_data.post_code}</p>
                            </div>
                            <div class="col-md-12 col-6">
                                <strong>Address</strong>
                                <br />
                                <p class="text-muted">{user_data.address}</p>
                            </div>
                        </div>

                        </div>
                        <div class="tab-pane fade" id="settings" role="tabpanel" aria-labelledby="profile-tab2">
                        <form method="put" class="needs-validation" onSubmit={handleSubmit}>
                            <div class="card-header">
                            <h4>Edit Profile</h4>
                            </div>
                            <div class="card-body">
                            <div class="row">
                                <div class="form-group col-md-6 col-12">
                                <label>First Name</label>
                                <input type="text" class="form-control" name='name' value={formData.name} onChange={handleInputChange} />
                                <div class="invalid-feedback">
                                    Please fill in the first name
                                </div>
                                </div>
                                <div class="form-group col-md-6 col-12">
                                    <label>Email</label>
                                    <input type="email" class="form-control" name='email' value={formData.email} onChange={handleInputChange} />
                                    <div class="invalid-feedback">
                                        Please fill in the email
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                
                                <div class="form-group col-md-5 col-12">
                                <label>Phone</label>
                                <input type="tel" class="form-control" name='phone_no' value={formData.phone_no} onChange={handleInputChange} />
                                </div>
                            </div>
                            
                            </div>
                            <div class="card-footer text-right">
                            <button class="btn btn-primary">Save Changes</button>
                            </div>
                        </form>
                        <div class="card-footer text-right">
                        <button
                            className="btn btn-danger"
                            onClick={() => handleDelete(id)}  // Corrected this to be a function
                            disabled={loading}
                            >
                            {loading ? 'Deleting...' : 'Delete'}
                            </button>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </section>
        </div>
    )
}

export default Customer_profile