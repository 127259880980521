
import React from 'react'
import Cardsmall from './small_card';
import { Link, useParams } from 'react-router-dom';
import useFetchDataFromApi from '../fetch_data_from_api';

function Problem_list() {
    
    const { service_type, bodypart_id } = useParams();
    const apiUrl = `/api/get_body_part_wise_problem/${service_type}/${bodypart_id}/`;
    const { data, loading, error } = useFetchDataFromApi(apiUrl);
  return (
    <div id="app">
            <div class="main-wrapper main-wrapper-1 vh-100">
                <div class="navbar-bg"></div>
                <div class="main-content">
                    <section class="section">
                        <div class="row ">
                            {data.map((data,counter)=>(
                                
                                <Cardsmall data={data}/>
                              
                            ))}
            

                        </div>
                    </section>
                </div>
            </div>
        </div>
  )
}

export default Problem_list